$mainFF: "Poppins", sans-serif;
$secoFF: "BalooChettan", sans-serif;

// ** Mixin
@mixin text-style($family, $size, $weight) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  font-style: normal;
  line-height: 1.2em;
  color: #000000;
}

// ** Text Styles
//? ========== H1
.text-poppins-h1-bold {
  @include text-style($mainFF, 49px, 700);
}

.text-poppins-h1-regular {
  @include text-style($mainFF, 49px, 400);
}

.text-baloochettan-h1 {
  @include text-style($secoFF, 49px, normal);
}

//? ========== H2
.text-poppins-h2-bold {
  @include text-style($mainFF, 39px, 700);
}

.text-poppins-h2-regular {
  @include text-style($mainFF, 39px, 400);
}

.text-baloochettan-h2 {
  @include text-style($secoFF, 39px, normal);
}

//? ========== H3
.text-poppins-h3-bold {
  @include text-style($mainFF, 31px, 700);
}

.text-poppins-h3-regular {
  @include text-style($mainFF, 31px, 400);
}

.text-baloochettan-h3 {
  @include text-style($secoFF, 31px, normal);
}

//? ========== H4
.text-poppins-h4-bold {
  @include text-style($mainFF, 25px, 700);
}

.text-poppins-h4-regular {
  @include text-style($mainFF, 25px, 400);
}

.text-poppins-h4-light {
  @include text-style($mainFF, 25px, 300);
}

.text-baloochettan-h4 {
  @include text-style($secoFF, 25px, normal);
}

//? ========== Headline
.text-poppins-headline-bold {
  @include text-style($mainFF, 20px, 700);
}

.text-poppins-headline-regular {
  @include text-style($mainFF, 20px, 400);
}

.text-poppins-headline-light {
  @include text-style($mainFF, 20px, 300);
}

.text-baloochettan-headline {
  @include text-style($secoFF, 20px, normal);
}

//? ========== Body
.text-poppins-body-bold {
  @include text-style($mainFF, 16px, 700);
}

.text-poppins-body-semibold {
  @include text-style($mainFF, 16px, 600);
}

.text-poppins-body-regular {
  @include text-style($mainFF, 16px, 400);
}

.text-poppins-body-light {
  @include text-style($mainFF, 16px, 300);
}

.text-baloochettan-body {
  @include text-style($secoFF, 16px, normal);
}

//? ========== Subtitle
.text-poppins-subtitle-bold {
  @include text-style($mainFF, 13px, 700);
}

.text-poppins-subtitle-semibold {
  @include text-style($mainFF, 13px, 600);
}

.text-poppins-subtitle-regular {
  @include text-style($mainFF, 13px, 400);
}

.text-baloochettan-subtitle {
  @include text-style($secoFF, 13px, normal);
}

//? ========== Caption
.text-poppins-caption-bold {
  @include text-style($mainFF, 11px, 700);
}

.text-poppins-caption-regular {
  @include text-style($mainFF, 11px, 400);
}

.text-baloochettan-caption {
  @include text-style($secoFF, 11px, normal);
}

//? ========== Footnote
.text-poppins-footnote-bold {
  @include text-style($mainFF, 10px, 700);
}

.text-poppins-footnote-regular {
  @include text-style($mainFF, 10px, 400);
}

.text-baloochettan-footnote {
  @include text-style($secoFF, 10px, normal);
}
