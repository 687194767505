$colors: (
  // Backgrounds
  background1: #fbfcfc,
  whiteColor: #ffffff,

  // Roxo
  purple50: #f8f6fe,
  purple100: #f0edfc,
  purple200: #e2dcf9,
  purple300: #cfc6f6,
  purple400: #bdb0f3,
  purple500: #a593ee,
  purple600: #957feb,
  purple700: #7d6ebb,
  purple800: #5b4e93,
  purple900: #4521d4,
  purple950: #34199f,

  // Turquesa
  turquoise50: #effbfb,
  turquoise100: #e3f7f7,
  turquoise200: #bfeded,
  turquoise300: #9be3e3,
  turquoise400: #67d5d5,
  turquoise500: #36c4c4,
  turquoise600: #30b0b0,
  turquoise700: #2b9c9c,
  turquoise800: #248484,
  turquoise900: #195c5c,
  turquoise950: #124040,

  // Azul
  blue50: #f2fafd,
  blue100: #e1f4fa,
  blue200: #c7eaf5,
  blue300: #a0dcee,
  blue400: #75cce6,
  blue500: #46bade,
  blue600: #25a8d0,
  blue700: #2093b6,
  blue800: #1c7e9c,
  blue900: #13576c,
  blue950: #0e3f4e,

  // Salmão
  salmon50: #fcf7f7,
  salmon100: #faf0f0,
  salmon200: #f3dddd,
  salmon300: #ecc6c6,
  salmon400: #e4afaf,
  salmon500: #db9494,
  salmon600: #d58181,
  salmon700: #cc5c43,
  salmon800: #bf4040,
  salmon900: #8d2f2f,
  salmon950: #632121,

  // Verde
  green50: #f4fbf3,
  green100: #e9f8e8,
  green200: #d3f0d1,
  green300: #bae8b6,
  green400: #9cde97,
  green500: #80d478,
  green600: #54c549,
  green700: #41ae37,
  green800: #368f2e,
  green900: #296c22,
  green950: #1c4917,

  // Rosa
  pink50: #fcf8f9,
  pink100: #f9f0f3,
  pink200: #f1dae2,
  pink300: #eac7d4,
  pink400: #e2b1c2,
  pink500: #d794ac,
  pink600: #d0819c,
  pink700: #c66788,
  pink800: #b6446c,
  pink900: #86324f,
  pink950: #612439,

  // Marrom
  brown50: #fcf7f7,
  brown100: #faeeeb,
  brown200: #f3d9d3,
  brown300: #ecc4bb,
  brown400: #e5ac9f,
  brown500: #db8c7b,
  brown600: #d47863,
  brown700: #cc5c43,
  brown800: #b04830,
  brown900: #803423,
  brown950: #60271a,

  // Cinza
  gray50: #f9f9fb,
  gray100: #f4f3f7,
  gray200: #e3e1ea,
  gray300: #d4d2e0,
  gray400: #c3bfd3,
  gray500: #b1acc6,
  gray600: #a19bbb,
  gray700: #8e86ac,
  gray800: #776e9b,
  gray900: #564f73,
  gray950: #3b364f,

  // Laranja
  orange50: #fef9f5,
  orange100: #fdf1e7,
  orange200: #fce0ca,
  orange300: #fad0ae,
  orange400: #f7ba87,
  orange500: #f5a463,
  orange600: #f28831,
  orange700: #e6700f,
  orange800: #c05d0c,
  orange900: #864109,
  orange950: #653106
);

@function get-color($color-name) {
  @return map-get($colors, $color-name);
}

@each $name, $color in $colors {
  .go-text-color-#{$name} {
    color: $color !important;

    &.with-hover {
      transition: background-color 150ms ease-in;
      &:hover {
        background-color: darken($color, 5) !important;
      }
    }
  }

  .go-background-color-#{$name} {
    background-color: $color !important;

    &.with-hover {
      transition: background-color 150ms ease-in;
      &:hover {
        background-color: darken($color, 5) !important;
      }
    }
  }

  .go-border-color-#{$name} {
    border-color: $color !important;
  }
}

// Uso de cores específicas
// get-color(purple500) => Busca a cor específica
