* {
  font-size: 14px;
  margin: 0;
  padding: 0;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

img {
  max-width: 100%;
  a {
    border: none;
  }
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

i {
  margin-right: 8px;
}

embed,
video,
iframe,
iframe[style] {
  max-width: 100%;
  height: auto;
}

.embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  -webkit-text-fill-color: #555555 !important;
}
