@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "BalooChettan";
  src: url("/assets/fonts/baloo-chettan/BalooChettan-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Poppins", sans-serif;
}
